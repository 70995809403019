import axios from 'axios';
import {store} from 'helpers';
import {authActions} from "../redux/actions";

const BASE_URL = `${process.env.REACT_APP_PRICE_API_URL}`;

export const priceApiService = {
    getLocation,
    getPricesByDistance,
    getPricesByPostCode,
    getSurchargesData,
    uploadSurchargesData,
    getPricesList,
    checkCode,
    useCode,
    getDedicated,
    updateDedicated,
    deleteDedicated,
    getInvitations,
    acceptInvitation,
    sendInvitation,
    updateCustomers,
    getConfiguration,
    updateConfiguration
};

function getGenericConfig(url) {
    const config = {
        url: BASE_URL + url,
    };
    const token = localStorage.getItem('priceApiToken') || null;
    const language = store.getState().translationReducer.locale || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-language': language
    };
    return config;
}

function getCooperationConfig(url) {
    const config = {
        url: BASE_URL + url,
    };
    const token = localStorage.getItem('cooperationApiToken') || null;
    const language = store.getState().translationReducer.locale || null;
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-language': language
    };
    return config;
}

async function apiRequest(config) {
    try {
        const response = await axios(config);

        return response.statusText === 'OK' || response.status === 204 || response.status === 201 || response.status === 200
            ? Promise.resolve(response.data)
            : Promise.reject(response.data);

    } catch (error) {
        if (error.response.status === 401) {
            store.dispatch(authActions.logout());
        }
        return Promise.reject(error.response);
    }
}

function getLocation(addressData) {
    let googleQueryString = "";
    Object.keys(addressData).forEach( key => {
        if (addressData[key] !== "?") {
            googleQueryString += addressData[key];
            googleQueryString += " "
        }
    });
    const config = {
        method: 'post',
        ...getGenericConfig('api/maps/getPoints'),
        data: {
            query: googleQueryString,
            }
            // address: {
            //     countryCode:addressData.country,
            //     postCode:addressData.postCode,
            //     streetLine1:addressData.address,
            //     city: addressData.city
            // }
        }
    return apiRequest(config);
}

function checkCode(code){
    const config = {
        method: 'get',
        ...getGenericConfig(`api/confirmation_codes/get_code_details/${code}`),
    }
    return apiRequest(config);
}

function useCode(codeData){
    const {code} = codeData;
    //delete codeData["code"];

    const config = {
        method: 'put',
        ...getGenericConfig(`api/confirmation_codes/update/${code}`),
        data:codeData
    }

    return apiRequest(config);
}

function getPricesByDistance(pricesData, downloadExample = false) {
    let url = 'api/Services/Freight/PricesByDistance/export';
    let params =  {
        country_from:pricesData.countryFrom,
        country_to:pricesData.countryTo,
    }
    if (downloadExample){
        params = {...params, template:true}
    }
    const config = {
        method: 'get',
        ...getCooperationConfig(url),
        params
    }
    return apiRequest(config);
}

function getPricesByPostCode(pricesData,downloadExample = false) {
    let url = 'api/Services/Freight/PricesByCodes/export'
    let params =  {
        country_from:pricesData.countryFrom,
        country_to:pricesData.countryTo,
        postcode_from:pricesData.postCode
    }
    if (downloadExample){
        params = {...params, template:true}
    }
    const config = {
        method: 'get',
        ...getCooperationConfig(url),
        params
    }
    return apiRequest(config);
}


function getPricesList(url) {
    const config = {
        method: 'get',
        ...getCooperationConfig('api/Services/Freight/' + url),
    }
    return apiRequest(config);
}

function getSurchargesData(url) {
    const config = {
        method: 'get',
        ...getCooperationConfig('api/Services/Freight/Surcharges/' + url),
    }
    return apiRequest(config);
}

function uploadSurchargesData(url, data) {
    const config = {
        method: 'post',
        ...getCooperationConfig('api/Services/Freight/Surcharges/' + url),
        data
    }
    return apiRequest(config);
}

function getDedicated(url) {
    const config = {
        method: 'get',
        ...getCooperationConfig('api/Services/Dedicated/' + url),
    }
    return apiRequest(config);
}

function updateDedicated(url, data) {
    const config = {
        method: 'post',
        ...getCooperationConfig('api/Services/Dedicated/' + url),
        data
    }
    return apiRequest(config);
}

function deleteDedicated(url, data) {
    const config = {
        method: 'delete',
        ...getCooperationConfig('api/Services/Dedicated/' + url),
        data
    }
    return apiRequest(config);
}

function getInvitations(){
    const config = {
        method: 'get',
        ...getGenericConfig(`api/Invitations/forCustomer`),
    }
    return apiRequest(config);
}

function acceptInvitation({uuid,name}){
    const config = {
        method: 'put',
        ...getGenericConfig(`api/Invitations/forCarrier`),
        data:{uuid, invited_name:name}
    }
    return apiRequest(config);
}

function sendInvitation({email, name, lang = 'de'}){
    const config = {
        method: 'post',
        ...getGenericConfig(`api/Invitations/forCustomer`),
        data:{email, inviting_name:name, lang}
    }
    return apiRequest(config);
}

function updateCustomers(customersData){
    const config = {
        method: 'post',
        ...getGenericConfig(`api/customers/discount`),
        data:customersData
    }
    return apiRequest(config);
}

function getConfiguration(url) {
    const config = {
        method: 'get',
        ...getCooperationConfig('api/Services/Freight/configuration/' + url),
    }
    return apiRequest(config);
}

function updateConfiguration(url, data) {
    const config = {
        method: 'post',
        ...getCooperationConfig('api/Services/Freight/configuration/' + url),
        data
    }
    return apiRequest(config);
}