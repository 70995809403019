import React from "react";
import { connect } from 'react-redux'
import {Table, Row, Col} from 'reactstrap';
import MediaQuery from 'react-responsive';
import {translate} from 'services/translationService';
import {getInvoices} from 'redux/actions/invoices'
import Loader from "components/Loader/Loader";
import InvoiceSingleRow from "./InvoiceSingleRow";
import Paginator from "components/Paginator/Paginator";
import {paginationActions} from "../../redux/actions";
import {pageDidChange} from "../../helpers/paginator";
import HelperButton from "../../components/Helper/HelperButton";
export const INVOICE_PAGINATOR = 'invoicePaginator';


class Invoices extends React.Component {

    constructor(props) {
        super(props);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerPageChange = this.handlePerPageChange.bind(this);
        this.dispatch = this.props.dispatch;
    }

    componentDidMount() {
        this.dispatch(getInvoices({
            page: this.props.pageParams.page,
            perPage: this.props.pageParams.perPage
        }));
    }

    componentDidUpdate(prevProps) {
        if (pageDidChange(prevProps.pageParams,  this.props.pageParams)){
            this.dispatch(getInvoices({
                perPage: this.props.pageParams.perPage,
                page: this.props.pageParams.page
            }));
        }
    }

    handlePageChange(pageNumber, indexStart) {
        this.dispatch(paginationActions.pushChangePage(INVOICE_PAGINATOR, pageNumber, indexStart));
    }

    handlePerPageChange(perPage) {
        this.dispatch(paginationActions.changePerPage(INVOICE_PAGINATOR, perPage));
    }

    render() {
        if (this.props.isFetching) {
            return (
                <div className="content">
                    <Loader/>
                </div>
            )
        }
        const {invoices, meta} = this.props;
        const pagesCount = Math.ceil(meta.total / meta.perPage);
        const page = this.props.pageParams.page;
        const indexStart = this.props.pageParams.indexStart;

        return (
            <div className="content">
                <Row style={{position:'relative'}}>
                    <Col md={12}>
                        <h4>{translate("All Invoices assigned to carrier")}</h4>
                    </Col>
                    <HelperButton helperId="carrier-invoices-list" />
                </Row>
                <Row>
                    <Col md={12} >

                        <Table hover bordered>
                            <thead>
                            <tr>
                                <th>{ translate('Number') }</th>
                                <th>{ translate('Date') }</th>
                                <th>{ translate('Netto / Brutto') }</th>
                                <th>{ translate('Status') }</th>
                                <th>{ translate('Action') }</th>
                            </tr>
                            </thead>

                                <tbody>
                                {invoices.map((invoice, i) => {
                                    return <React.Fragment key={i}><InvoiceSingleRow invoice={invoice}/></React.Fragment>
                                })}
                                </tbody>
                        </Table>

                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mt-3">
                        {pagesCount > 1 && <MediaQuery minDeviceWidth={860}>
                            {(matches) => {
                                let maxPages = matches ? 10 : 3;

                                return <Paginator
                                    pageChangeHandler={this.handlePageChange}
                                    perPageHandler={this.handlePerPageChange}
                                    pagesCount={pagesCount}
                                    currentPage={page}
                                    perPage={this.props.pageParams.perPage}
                                    indexStart={indexStart}
                                    maxPages={maxPages}
                                />
                            }}
                        </MediaQuery>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

Invoices.defaultProps = {
    invoices: [],
    meta: {},
    isFetching: null,
    error: false,
};

const mapStateToProps = (state) => {
    return {
        invoices: state.invoicesReducer.invoices,
        meta: state.invoicesReducer.meta,
        isFetching: state.invoicesReducer.isFetching,
        error: state.invoicesReducer.error,
        pageParams: state.paginationReducer.invoicePaginator,
    };
}
export default connect(mapStateToProps)(Invoices);
